<!-- 托管服务 托管学生 详情 -->
<template>
    <div class="meeting-info">
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow"> 
				<global-page-back :detailPageTitle="detailData.data.title+'('+depositTime+')'" @handlerGlobalType="handleBack" ></global-page-back>
                <expand-filter :formData="formData" @clickBtn="clickBtn" marginBottom="10px" marginTop="10px" borderRadius="4px"
                    @changeBtnFormType="changeBtnFormType" :closeWidth="900"  @changeSel="changeSel"></expand-filter>
                <table-data :config="table_config" :tableData="table_data" v-loading="loadingTable"
                    backgroundHeader="#FAFBFC" :headerStyle="{ height: '50px' }" :rowStyle="{ height: '50px' }" ref="table"
                    id="table">
                    <template v-slot:operation="slotData">
                        <xk-button @click="handleDelete(slotData.data)" v-hasPermi="['depositStu:delete']" size="mini"
                            style="color: #F26231; border: none;background: none;">
                            删除
                        </xk-button>
                    </template>
                </table-data>
                <pagination :total="total" :page.sync="listQuery.pageNum" :limit.sync="listQuery.pageRow"
                    @pagination="getList(true)" />
                <!-- 添加 -->
                <dialog-wrapper :dialogObj="dialogObj" @handleClose="handleClose">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="right" label-width="94px" class="common-form">
                        <el-form-item label="年级" prop="gradeId">
                            <el-select v-model="ruleForm.gradeId" placeholder="请选择年级" clearable filterable
                                @change="handleGradeChange" class="input-width-medium">
                                <el-option v-for="item in gradeOptions" :key="item.id" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="班级" prop="classId">
                            <el-select v-model="ruleForm.classId" placeholder="请选择班级" clearable filterable
                            @change="handleClassChange" class="input-width-medium">
                                <el-option v-for="item in classOptions" :key="item.id" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="学生" prop="onlyCodeList">
                            <el-select v-model="ruleForm.onlyCodeList" collapse-tags multiple placeholder="请选择学生(多选)"
                                 filterable class="input-width-medium">
                                <el-option v-for="(item, index) in studentOptions" :label="item.label" :value="item.value"
                                    :key="index">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <div style="text-align: right;width:546px;">
                            <el-button @click="handleClose" class="dialog-reset-button">取消</el-button>
                            <el-button @click="submitForm()" type="primary">保存</el-button>
                        </div>
                    </el-form>
                </dialog-wrapper>
                <!-- 导入 -->
                <DialogWrapper :dialogObj="importFamilyObj" @handleClose="handleImportFamilyClose">
                    <ImportState ref="importFamilyRef" :import-form="importFamilyRuleForm"
                        @download="handleImportFamilyDownload" @next="handleImportFamilyNext" @save="handleImportFamilySave"
                        @close="handleImportFamilyClose" />
                </DialogWrapper>
            </div>
        </div>
        <error v-else :errorShow="errorShow"></error>
    </div>
</template>

<script>
import {
    FilterData,
    Loading,
    Pagination,
    DialogWrapper
} from "common-local";
import ExpandFilter from "../Sub/ExpandFilter.vue";
import TableData from "../Sub/TableData.vue";
import selectTree from "../Sub/selectTree";
import { CategoryManagementModel } from "@/models/SServiceSchReport";
import {CustodialServicesModel} from "@/models/CustodialServices";
import ImportState from './ImportState/index.vue';
import { downloadFile } from "@/libs/utils";
import GlobalPageBack from "../Sub/GlobalPageBack/index.vue";

// 逻辑运算
import system from '@/operations/system.js';
export default {
    name: "TrusteeshipStudentInfo",
    components: {
        selectTree,
        FilterData,
        Loading,
        TableData,
        Pagination,
        ExpandFilter,
        ImportState,
        DialogWrapper,
		GlobalPageBack
    },
    props: {
        detailData: {
            type: Object,
            default: ""
        }
    },
    data() {
        return {
            loadingShow: false,
            errorShow: false,
            loadingTable: false,
            total: 0,
            depositTime:'',
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                schoolId: '',
                gradeId: '',
                classId: '',
                studentName:'',
                onlyCode:'',
                studyNo:"",
                depositId:this.detailData.data.id,
            },
            // 头部筛选
            formData: {
                btnFormType: true,
                formInline: {
                    teacherName: "",
                },
                data: [
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "年级",
                        key: "gradeId",
                        list: [],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "班级",
                        key: "classId",
                        list: [],
                    },
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "输入姓名",
                        key: "studentName",
                    },
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "输入唯一号",
                        key: "onlyCode",
                    },
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "输入学号",
                        key: "studyNo",
                    }
                ],
                btnList: [{
                    type: "primary",
                    text: "查询",
                    fn: "primary",
                    auth: ["depositStu:list"],
                },
                {
                    type: "enquiry",
                    text: "重置",
                    fn: "reset",
                },
                {
                    type: "enquiry",
                    text: "导入",
                    fn: "import",
                    auth: ["depositStu:impost"],
                },
                {
                    type: "primary",
                    text: "添加",
                    fn: "add",
                    auth: ["depositStu:add"],
                },
                ]
            },
            table_config: {
                thead: [{
                    label: "姓名",
                    prop: "studentName",
                    className: 'text-spacing-reduction'
                }, {
                    label: "年级",
                    prop: "gradeName",
                    className: 'text-spacing-reduction'
                }, {
                    label: "班级",
                    prop: "className",
                    className: 'text-spacing-reduction'
                }, {
                    label: "学号",
                    prop: "studyNo",
                    labelWidth: "190",
                    className: 'text-spacing-reduction'
                }, {
                    label: "唯一号",
                    prop: "onlyCode",
                    labelWidth: "190"
                }, {
                    label: "创建人",
                    prop: "createName",
                    labelWidth: "190"
                }, {
                    label: "创建时间",
                    prop: "createTime",
                    labelWidth: "190"
                }, {
                    label: "操作",
                    type: "slot",
                    slotName: "operation",
                    labelWidth: "130",
                    className: 'text-spacing-reduction'
                }],
                check: false,
                height: ""
            },
            table_data: [],
            // 添加弹出框配置
            dialogObj: {
                title: '添加',
                dialogVisible: false,
                width: '640px'
            },
            ruleForm: {
                schoolId: this.$store.state.schoolId,
                onlyCodeList:[],
                depositId:'',
                gradeId:'',
                classId:'',
            },
            rules: {
                gradeId: [{
                    required: true,
                    message: '请选择年级',
                    trigger: ['change', 'blur']
                }],
                classId: [{
                    required: true,
                    message: '请选择班级',
                    trigger: ['change', 'blur']
                }],
                onlyCodeList: [{
                    required: true,
                    message: '请选择学生（多选）',
                    trigger: ['blur','change']
                }],
            },
            //导入
            importFamilyObj: {
                title: '导入',
                dialogVisible: false,
                width: '900px'
            },
            importFamilyRuleForm: {
                schoolId: this.$store.state.schoolId,
                active: 0,
                file: '',
                depositId:this.detailData.data.id,//托管服务id
                handleType: '0',// 0继续执行  1终止导入
            },
            gradeOptions: [], // 年级下拉框数据
            classOptions: [], // 班级下拉框数据
            studentOptions: [], // 学生下拉框数据
        }
    },
    created() {
        this.init()
        this.getList()
        this.getGradList()
    },
    computed: {
        backImg() {
            return require('@/assets/images/back3.png');
        },
    },
    mounted() {
        this.table_config.height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 98
    },
	beforeDestroy(){
		this.$eventDispatch('setGlobalPageType', 'list');
	},
    methods: {
        init(){
            console.log(this.detailData.data.menuList,'this.detailData.menuList');
            this.gradeOptions = this.detailData.data.menuList.map(i => ({ label: i.name, value: i.id }))
            this.depositTime = this.detailData.data.depositStartDate.split(' ')[0] +' — '+ this.detailData.data.depositEndDate.split(' ')[0];
			this.$eventDispatch('setGlobalPageType', 'form');
        },
        /**
         * @Description: 返回上一页
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-10 11:13:03
         */
        handleBack() {
            this.$emit('toList', {
                //   productId: this.detailData.productId
            })
        },
        /**
         * @Description: 获取列表数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-03-09 11:46:53
         */
        getList(isPaging) {
            if (!isPaging) {
                this.listQuery.pageNum = 1
            }
            this.listQuery.schoolId = this.$store.state.schoolId
            const custodialServicesModel = new CustodialServicesModel();
            custodialServicesModel.getDepositStudentList(this.listQuery).then(res => {
                if (res.data.code === "200") {
                    this.total = res.data.data.total
                    this.table_data = res.data.data.rows
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        },
        /**
         * @Description: 获取年级下拉数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-12 11:30:05
         */
        getGradList () {
            const custodialServicesModel = new CustodialServicesModel();
            custodialServicesModel.getYearDropDownList({
                schoolId: this.$store.state.schoolId,
                jobScope: ["2", "3"],
            }).then((res) => {
                if (res.data.code === '200') {
                    console.log(res,'0000');
                    // this.gradeOptions = res.data.data.map(i => ({ label: i.name, value: i.id }))
                    this.formData.data.forEach((item) => {
                        if (item.key === 'gradeId') {
                            item.list = res.data.data.map(i => ({ label: i.name, value: i.id }))
                            // item.list.unshift({ label: '全部', value: '' })
                        }
                    })
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
            },
        /**
         * @Description: 获取班级下拉数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-12 11:57:19
         */
        getClassList(gradeId){
            const classIndex = this.formData.data.findIndex(i => i.key === 'classId')
            this.formData.data[classIndex].value = ''
            this.formData.data[classIndex].list = []
            this.ruleForm.classId = ''
            this.classOptions=[]
            if(gradeId){
                 const custodialServicesModel = new CustodialServicesModel();
                custodialServicesModel.getClassDropDownList({
                    schoolId: this.$store.state.schoolId,
                    gradeId: gradeId,
                    jobScope: ["2", "3"],
                }).then((res) => {
                    if (res.data.code === '200') {
                        console.log(res,'1111');
                        // this.classOptions = res.data.data.map(i => ({ label: i.name, value: i.id }))
                        this.formData.data.forEach((item) => {
                            if (item.key === 'classId') {
                                item.list = res.data.data.map(i => ({ label: i.name, value: i.id }))
                                // item.list.unshift({ label: '全部', value: '' })
                            }
                        })
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg)
                    }
                })
            }

        },
        /**
         * @Description: 选择年级
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-10 11:37:46
         */
        handleGradeChange(data) {
            this.ruleForm.classId = ''
            this.classOptions=[]
            console.log('222222222222',this.detailData.data.menuList,data);
            this.classOptions = this.detailData.data.menuList.filter(i => i.id === data)[0].child.map(i => ({ label: i.name, value: i.id }))
        },
        // 修改选择
        changeSel(data) {
            // console.log("[changeSel]data", data)
            if (data.key === 'gradeId') {
                this.getClassList(data.value)
            }
        },
        /**
         * @Description: 选择班级
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-13 09:48:17
         */
        handleClassChange(data) {
            console.log('333333333333333',data);
            this.getStudentList(data)
        },
        /**
         * @Description: 获取学生列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-13 09:45:26
         */
        getStudentList(classId){
            const custodialServicesModel = new CustodialServicesModel();
            custodialServicesModel.getStudentList({
                schoolId: this.$store.state.schoolId,
                classId: classId,
                depositId:this.detailData.data.id,
            }).then((res) => {
                if (res.data.code === '200') {
                    console.log(res.data.data,'3333');
                    this.studentOptions = res.data.data.map(i => ({ label: i.studentName + ' (' + i.onlyCode + ')', value: i.onlyCode }))
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        },
        /**
         * @Description: 展开收起
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-10 14:33:03
         */
        changeBtnFormType() {
            this.formData.btnFormType = !this.formData.btnFormType;
            this.$nextTick(() => {
                this.table_config.height = document.body.clientHeight - document.getElementById("table").offsetTop - 136;
            })

        },
        /**
         * @Description: 查询、重置、导入、添加
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-10 14:33:19
         */
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    });
                    this.getList(1);
                    break;
                case "reset":// 重置
                    this.formData.data.forEach((item) => {
                        item.value = "";
                        this.listQuery[item.key] = "";
                    });
                    this.getList(1);
                    break;
                case "import":// 导入
                    this.importFamilyObj.dialogVisible = true
                    break;
                case "add":// 添加
                    this.dialogObj.dialogVisible = true
                    break;
                default:
                    break;
            }
        },
        /**
         * @Description: 关闭\取消添加弹框
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-03-09 15:14:11
         */

        handleClose() {
            this.$refs.ruleForm.resetFields()
            Object.keys(this.ruleForm).forEach((key) => {
                this.ruleForm[key] = ''
            })
            this.dialogObj.dialogVisible = false
            this.ruleForm.schoolId = this.$store.state.schoolId
        },
        /**
         * @Description: 添加---确定按钮
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-03-09 15:15:31
         */
        submitForm() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.ruleForm.depositId = this.detailData.data.id
                    console.log(this.ruleForm,'this.ruleForm');
                    const custodialServicesModel = new CustodialServicesModel();
                    custodialServicesModel.addHostingStu(this.ruleForm).then((res) => {
                        if (res.data.code === '200') {
                            this.$message.success('保存成功')
                            this.handleClose();
                            this.getList();
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    })
                }
            })
        },
        /**
         * @Description: 操作-删除
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-03-13 11:37:41
         */
        handleDelete(data) {
            this.$confirm('删除之后不可恢复，是否确认删除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const custodialServicesModel = new CustodialServicesModel();
                custodialServicesModel.deteleDepositStudent({ id: data.id }).then((res) => {
                    if (res.data.code === '200') {
                        this.$message.success('删除成功')
                        this.getList()
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
            }).catch(() => {
            })
        },
        /**
         * @Description: 导入下载模板
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2022-12-19 15:20:32
         */
        handleImportFamilyDownload() {
            const custodialServicesModel = new CustodialServicesModel();
            custodialServicesModel.getImportTemplate().then((res) => {
                if (res.data.code === '200') {
                    let fileName = res.data.data.split('/')[3]
                    downloadFile({ url: res.data.data, method: 'get', fileName: fileName }, () => {
                    }, () => {
                    })
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        },
        /**
         * @Description: 导入-下一步
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2022-12-19 15:21:35
         */
        async handleImportFamilyNext() {
            if (this.importFamilyRuleForm.file === '') {
                return this.$message.warning('请上传文件')
            }
            this.importFamilyRuleForm.active++
        },
        /**
         * @Description: 导入-保存
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2022-12-19 15:22:43
         */
        handleImportFamilySave() {
            const file = this.importFamilyRuleForm.file
            let formData = new FormData();
            console.log(this.importFamilyRuleForm, '数据')
            formData.append('file', file.raw)
            formData.append('schoolId', this.$store.state.schoolId)
            formData.append('handleType', this.importFamilyRuleForm.handleType)
            formData.append('depositId', this.importFamilyRuleForm.depositId)
            console.log(formData,'formData');
            const custodialServicesModel = new CustodialServicesModel();
            custodialServicesModel.importHostingStu(formData).then((res) => {
                if (res.data.code === "200") {
                    this.importFamilyRuleForm.active++;
                    console.log(res.data.data, '导入返回数据');
                    this.importFamilyRuleForm.successData = res.data.data.count
                    // if (!this.importFamilyRuleForm.successData.rightCount) {
                    //     this.importFamilyRuleForm.successData.rightCount = 0
                    // }
                    this.$message.success('导入成功')
                    this.getList()
                    if(res.data.data.errorId){
                        this.downloadImportFamilyErrorList(res.data)
                    }
                } else if (res.data.code === "400") {
                    this.$message.error(res.data.msg);
                } else {
                    this.getList()
                    this.downloadImportFamilyErrorList(res.data)
                }
            })
        },
        downloadImportFamilyErrorList(data) {
            console.log(data.errorId, '错误信息');
            this.$confirm(data.msg, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                downloadFile({
                    url: `/school/depositStudent/downloadError/${data.data.errorId}`,
                    method: 'get',
                    fileName: '导入错误信息表.xlsx',
                    // form: { paramData: data.info.paramData }
                }, () => {
                }, () => {

                })
            }).catch(() => {
            })
        },
        /**
         * @Description: 关闭导入弹框
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2022-12-19 15:23:36
         */
        handleImportFamilyClose() {
            this.importFamilyObj.dialogVisible = false
            this.importFamilyRuleForm.active = 0
            this.importFamilyRuleForm.schoolId = ''
            this.importFamilyRuleForm.file = ''
        },
    }
}
</script>

<style lang="scss" scoped>
.body-title {
    display: flex;
    background-color: #ffffff;
    align-items: center;
    margin-top: 8px;
    box-sizing: border-box;
    width: 100%;
    padding-left: 20px;
    height: 48px;
    border-bottom: 1px solid #EDF0F2;

    .back {
        height: 14px;
        line-height: 14px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2B2F33;
        border-right: 1px solid #E1E3E6;
        padding-right: 20px;
        cursor: pointer;

        &:hover {
            color: #3C7FFF;
        }

        .back-img {
            width: 10px;
            margin-right: 8px;

            &:hover {
                color: #3C7FFF;
            }
        }
        i {
            color: #363A40;
            &:hover {
                color: #3C7FFF;
            }
        }
    }

    .title {
        font-weight: bold;
        margin-left: 20px;
        font-size: 14px;
    }

    .return-btn {
        display: flex;
        color: #363A40;
        cursor: pointer;
        font-size: 14px;
        align-items: center;

        &:hover {
            color: #3C7FFF;
        }

        i {
            color: #363A40;
            margin-right: 5px;

            &:hover {
                color: #3C7FFF;
            }
        }
    }
}
</style>
